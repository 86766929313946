import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18n'

import { DotsLoader, RadioButtonList } from 'Components/Base'
import { ModalV2 } from 'Components/Base/ModalV2';
import ActionButton from 'Components/Base/ActionButton'

import CarePlanTemplateApi from 'Api/Place/CarePlanTemplate'

import banner from 'care_plan_template.svg'

const i18nOpts = { scope: 'patient.index.care_plan_templates_modal' }

class CarePlanTemplatesModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      carePlanTemplates: [],
      isLoading: false,
      isSubmitting: false,
      selectedTemplateId: 0,
      error: '',
    }

    this.selectTemplate = this.selectTemplate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    return new CarePlanTemplateApi().index().then((res) => {
      if (res.ok) {
        return res.json().then(({ carePlanTemplates }) => {
          this.setState({ carePlanTemplates, isLoading: false })
        })
      } else if (res.status === 422) {
        window.flash_messages.addMessage(t('modal_failure', i18nOpts))
        this.props.closeModal()
        return res.json().then(console.error)
      }
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true })

    if (this.state.selectedTemplateId) {
      const form = e.target;
      form.submit();
    } else {
      this.setState({ isSubmitting: false, error: 'required' })
    }
  }

  selectTemplate(selectedTemplateId) {
    if (!this.state.selectedTemplateId || !this.state.isSubmitting) {
      this.setState({ selectedTemplateId, isSubmitting: false, error: '' })
    }
  }

  footer() {
    return(
      <form
        className="form"
        method="get"
        onSubmit={this.handleSubmit}
        action={this.props.newCarePlanPath}
        noValidate
      >
        <input
          type="hidden"
          name="care_plan[care_plan_template_id]"
          value={this.state.selectedTemplateId}
        />
        <div className="ReactModal__ContentFooter--v2">
          <ActionButton
            className="button-subtle"
            content={t('cancel', i18nOpts)}
            onClick={this.props.closeModal}
            isLoading={this.state.isLoading || this.state.isSubmitting}
          />
          <ActionButton
            type="submit"
            content={t('create_action_plan', i18nOpts)}
            isLoading={this.state.isLoading || this.state.isSubmitting}
          />
        </div>
      </form>
    )
  }

  render() {
    return (
      <ModalV2 title={t('title', i18nOpts)} isOpen={this.props.isOpen} onClose={this.props.closeModal} footer={this.footer()}>
        <div className="ReactModal__ContentBanner--v2">
          <div className="ReactModal__ContentBanner-text">{t('content_banner', i18nOpts)}</div>
          <img src={banner} className="ReactModal__ContentBanner-image" />
        </div>
        {this.state.isLoading ? (
            <DotsLoader />
          ) : (
            <RadioButtonList
              header={t('list_header', i18nOpts)}
              onClick={this.selectTemplate}
              options={this.state.carePlanTemplates}
              selectedOptionId={this.state.selectedTemplateId}
              enableSearch={this.state.carePlanTemplates?.length > 10}
              error={this.state.error}
            />
          )}
      </ModalV2>
    )
  }
}

CarePlanTemplatesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  newCarePlanPath: PropTypes.string.isRequired,
}

export default CarePlanTemplatesModal
