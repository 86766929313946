import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CarePlanPaginator extends Component {
  constructor(props) {
    super(props);
    this.paginatorRef = null;

    this.setPaginatorRef = (element) => {
      this.paginatorRef = element;
    };

    this.createPaginatorObserver = this.createPaginatorObserver.bind(this);
    this.handleIntersect = this.handleIntersect.bind(this);
  }

  handleIntersect(entries, _observer) {
    const paginatorDiv = entries[0];
    if (
      paginatorDiv.isIntersecting &&
      this.props.hasMoreCarePlanPages
    ) {
      this.props.fetchNextPlanPage();
    }
  }

  createPaginatorObserver(targetEl) {
    let observer;

    const options = {
      root: null, // watch for intersection of paginatorRef element relative to viewport
      rootMargin: '0px',
      threshold: 0,
    };

    observer = new IntersectionObserver(this.handleIntersect, options);
    observer.observe(targetEl);
  }

  componentDidMount() {
    this.createPaginatorObserver(this.paginatorRef);
  }

  render() {
    return (
      <div ref={this.setPaginatorRef} style={{ height: '1px' }}></div>
    );
  }
}

CarePlanPaginator.propTypes = {
  fetchNextPlanPage: PropTypes.func.isRequired,
  hasMoreCarePlanPages: PropTypes.bool.isRequired,
};

export default CarePlanPaginator;
