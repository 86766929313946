import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { connect } from 'react-redux';

import Aside from '../NewContacts/Aside';
import Contact from '../NewContacts/Contact';
import Filters from './Filters';
import CarePlanPaginator from './CarePlanPaginator';

import { showContacts } from 'Actions/inbox/sideNav/contacts';
import { fetchCarePlanContacts } from 'Actions/inbox/sideNav/carePlans';
import { fetchChannelContacts } from 'Actions/inbox/sideNav/channel';
import { search } from 'Actions/inbox/sideNav/general';

import { getSideNavOpenState, getCarePlanFetchingState, getCarePlans, getSideNavMode } from 'Selectors/inbox';

import { getCurrentUser } from 'Selectors/currentUser';

const i18nOpts = { scope: 'inbox.side_nav.care_plans' };

const PAGE_SIZE = 25;

class CarePlansContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.renderCarePlans = this.renderCarePlans.bind(this);

    this.state = {
      mode: this.props.mode,
      pageNumber: 0,
    };
  }

  get info() {
    const userType = this.props.isPlaceUser ? 'health_care' : 'non_health_care';
    return t(`${userType}.${this.state.mode}.info`, i18nOpts);
  }

  handleClick(id, title) {
    if (this.props.mode === 'channelCarePlans') {
      this.props.fetchChannelContacts(id, title);
    } else {
      this.props.fetchContacts(id);
    }
  }

  renderCarePlans() {
    const { carePlans, isPlaceUser, search } = this.props;
    const { pageNumber } = this.state;

    const hasMoreCarePlanPages = carePlans.length - PAGE_SIZE * pageNumber > PAGE_SIZE;
    const fetchNextPage = () => this.setState({ pageNumber: pageNumber + 1 });

    const offsetIndex = pageNumber * PAGE_SIZE;
    const viewableCarePlans = carePlans.slice(0, offsetIndex + PAGE_SIZE);

    return (
      <Fragment>
        {isPlaceUser && <Filters search={(evt) => {
          this.setState({ pageNumber: 0 });
          search(evt)
        }} />}

        <p className="inbox-menu__info">{this.info}</p>

        <section className="inbox-menu__contacts">
          <div className="inbox-menu__contacts-scroll">
            {viewableCarePlans.length ? (
              <Fragment>
                {viewableCarePlans.map(({ id, patientName, patientPhoto, title }) => (
                  <Contact
                    key={id}
                    name={patientName}
                    photo={patientPhoto}
                    title={title}
                    titleProminence={true}
                    onClick={this.handleClick.bind(null, id, title)}
                    radio
                  />
                ))}
                <CarePlanPaginator
                  hasMoreCarePlanPages={hasMoreCarePlanPages}
                  fetchNextPlanPage={fetchNextPage}
                />
              </Fragment>
            ) : (
              <div className="inbox-menu__contact inbox-menu__contact--empty">
                {t('no_results', i18nOpts)}
              </div>
            )}
          </div>
        </section>
      </Fragment>
    );
  }

  render() {
    const { isOpen, back, isFetching } = this.props;

    return (
      <Aside
        isOpen={isOpen}
        isFetching={isFetching}
        onClick={back}
        header={t('header', i18nOpts)}
        render={this.renderCarePlans}
      />
    );
  }
}

CarePlansContainer.propTypes = {
  back: PropTypes.func.isRequired,
  carePlans: PropTypes.array,
  fetchChannelContacts: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPlaceUser: PropTypes.bool,
  mode: PropTypes.string,
  search: PropTypes.func.isRequired,
};

CarePlansContainer.defaultProps = {
  carePlans: [],
  isPlaceUser: false,
  mode: 'carePlan',
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  carePlans: getCarePlans(state),
  isOpen: getSideNavOpenState(state),
  isFetching: getCarePlanFetchingState(state),
  mode: getSideNavMode(state),
  isPlaceUser: getCurrentUser(state).isPlaceUser,
});

const mapDispatchToProps = (dispatch) => ({
  back: () => dispatch(showContacts()),
  fetchChannelContacts: (id, title) => dispatch(fetchChannelContacts(id, title)),
  fetchContacts: (id) => dispatch(fetchCarePlanContacts(id)),
  search: ({ target: { value } }) => dispatch(search(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarePlansContainer);
